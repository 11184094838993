import React from "react";
import BitfinexFunding from "./bitfinex/BitfinexFunding";

import "./ContentArea.css";

function ContentArea({ content }) {
    return (
        <div className="content-area">
            {/* 这里可以基于content来渲染不同的内容 */}
            {/* 例如使用条件渲染或者引入不同的子组件 */}
            {content.selection_one === "home" && <div>显示首页</div>}
            {content.selection_one === "bitfinex" && (
                <BitfinexFunding account={content.selection_two} />
            )}
            {content.selection_one === "binance" && (
                <div>显示Binance账户信息</div>
            )}
            {content === "default" && <div>默认内容区域</div>}
        </div>
    );
}

export default ContentArea;
