import React from "react";
import "./TopBar.css"; // 引入TopBar的CSS样式文件

function TopBar({ onTopBarSelectionChange, onLogout }) {
    return (
        <div className="top-bar">
            <div className="top-bar-content">
                <div
                    className="logo"
                    onClick={() => onTopBarSelectionChange("home")}
                >
                    Chainext Dashboard
                </div>
                <div className="top-bar-right">
                    <button className="logout-button" onClick={onLogout}>
                        Logout
                    </button>
                </div>
            </div>
        </div>
    );
}

export default TopBar;
