import React, { useState } from "react";
import "./SideBar.css";

function SideBar({ onSideBarSelectionChange }) {
    const [bitfinexOpen, setBitfinexOpen] = useState(false);

    const toggleBitfinex = () => {
        setBitfinexOpen(!bitfinexOpen);
    };

    return (
        <div className="sidebar">
            <ul className="sidebar-menu">
                <li className="sidebar-menu-item" onClick={toggleBitfinex}>
                    Bitfinex {bitfinexOpen ? "▼" : "▲"}
                </li>
                {bitfinexOpen && (
                    <>
                        <li
                            className="sub-menu-item"
                            onClick={() =>
                                onSideBarSelectionChange("bitfinex", "chainext")
                            }
                        >
                            chainext
                        </li>
                        <li
                            className="sub-menu-item"
                            onClick={() =>
                                onSideBarSelectionChange("bitfinex", "yqk")
                            }
                        >
                            yqk
                        </li>
                    </>
                )}
                <li
                    className="sidebar-menu-item"
                    onClick={() => onSideBarSelectionChange("binance")}
                >
                    Binance
                </li>
                {/* 更多的链接 */}
            </ul>
        </div>
    );
}

export default SideBar;
