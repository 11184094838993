import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import {
    BarChart,
    Bar,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";
import "react-datepicker/dist/react-datepicker.css";
import "./BitfinexFunding.css";

function BitfinexFunding(account) {
    const [data, setData] = useState(null);
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    const [startDate, endDate] = dateRange;
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);
    const [selectedButton, setSelectedButton] = useState(null);

    useEffect(() => {
        // 根据选项参数发送不同的请求
        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/api/v1/cex/bitfinex-daily-summary/?account_name=${account.account}`,
                { withCredentials: true }
            )
            .then((response) => {
                const parsedData = JSON.parse(response.data);
                setData(parsedData);
                // 设置最早和最晚日期
                if (parsedData.data && parsedData.data.length > 0) {
                    const dates = parsedData.data.map(
                        (entry) => new Date(entry.ledger_date)
                    );
                    const min = new Date(Math.min(...dates));
                    const max = new Date(Math.max(...dates));

                    setMinDate(min);
                    setMaxDate(max);
                    setDateRange([min, max]); // 设置默认日期范围为整个可选择范围
                }
                // 每次account改变时重置已选中的按钮
                setSelectedButton(null);
            })
            .catch((error) => {
                console.error(
                    `Error fetching ${account.account} account data:`,
                    error
                );
            });
    }, [account]);

    // 数据处理
    let chartData = [];
    if (data && data.data) {
        chartData = data.data.map((entry) => ({
            ledgerDate: new Date(entry.ledger_date),
            ledgerDateString: new Date(entry.ledger_date).toLocaleDateString(),
            dailyFundingPayment: entry.daily_funding_payment,
            startValue: entry.start_value,
            endValue: entry.end_value,
            netInflow: entry.daily_transfer_in - entry.daily_transfer_out,
            dailyReturnRate:
                entry.start_value === 0
                    ? 0
                    : entry.daily_funding_payment / entry.start_value,
            annualizedDailyReturnRate:
                entry.start_value === 0
                    ? 0
                    : (
                          (entry.daily_funding_payment / entry.start_value) *
                          365
                      ).toFixed(6),
        }));
    }

    // 数据筛选
    const filteredData = chartData.filter(
        (entry) => entry.ledgerDate >= startDate && entry.ledgerDate <= endDate
    );

    // 计算总额与年化收益率
    const totalFundingPayment = filteredData.reduce(
        (sum, entry) => sum + entry.dailyFundingPayment,
        0
    );
    const totalReturnRate =
        filteredData.reduce(
            (product, entry) => product * (1 + entry.dailyReturnRate),
            1
        ) - 1;
    const annualizedReturnRate = (totalReturnRate / filteredData.length) * 365; // 假设一年365天

    // 计算期初价值和期末价值
    const filteredStartValue =
        filteredData.length > 0 ? filteredData[0].startValue : 0;
    const filteredEndValue =
        filteredData.length > 0
            ? filteredData[filteredData.length - 1].endValue
            : 0;

    // 计算所选日期范围内的净转入资金
    const netInflow = filteredData.reduce(
        (sum, entry) => sum + entry.netInflow,
        0
    );

    // 计算账户价值纵坐标的最大和最小值
    const maxEndValue = Math.max(...filteredData.map((item) => item.endValue));
    const minEndValue = Math.min(...filteredData.map((item) => item.endValue));

    // 添加两个按钮的事件处理函数
    const handleLast7Days = () => {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(endDate.getDate() - 7); // 减去6天（包括今天在内总共7天）
        setDateRange([startDate, endDate]);
        setSelectedButton("last7Days");
    };

    const handleLastMonth = () => {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setMonth(endDate.getMonth() - 1); // 上一个月的同一天
        startDate.setDate(endDate.getDate()); // 保持同一天
        setDateRange([startDate, endDate]);
        setSelectedButton("lastMonth");
    };
    // 日期范围选择器的事件处理函数
    const handleDateChange = (update) => {
        setDateRange(update);
        setSelectedButton(null); // 重置按钮选中状态
    };
    // 添加重置按钮的处理函数
    const handleReset = () => {
        if (minDate && maxDate) {
            setDateRange([minDate, maxDate]); // 重置为默认日期范围
        }
        setSelectedButton(null); // 取消任何选中的按钮
    };
    return (
        <div className="container">
            <div>账户：{account.account}</div>
            {data ? (
                <div>
                    <div className="date-selection">
                        {/* 日期范围选择器 */}
                        <DatePicker
                            className="date-picker"
                            selectsRange
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => handleDateChange(update)}
                            minDate={minDate}
                            maxDate={maxDate}
                        />
                        <button
                            className={`button ${
                                selectedButton === "last7Days" ? "selected" : ""
                            }`}
                            onClick={handleLast7Days}
                        >
                            近7天
                        </button>
                        <button
                            className={`button ${
                                selectedButton === "lastMonth" ? "selected" : ""
                            }`}
                            onClick={handleLastMonth}
                        >
                            近1个月
                        </button>
                        <button
                            className={`button ${
                                selectedButton === "reset" ? "selected" : ""
                            }`}
                            onClick={handleReset}
                        >
                            重置
                        </button>
                    </div>
                    <div className="data-cards-container">
                        {/* 数据展示卡片 */}
                        <div className="data-card">
                            账户期初价值: ${filteredStartValue.toFixed(2)}
                        </div>
                        <div className="data-card">
                            账户期末价值: ${filteredEndValue.toFixed(2)}
                        </div>
                        <div className="data-card">
                            期间净流入: ${netInflow.toFixed(2)}
                        </div>
                        <div className="data-card">
                            期间利息收入: ${totalFundingPayment.toFixed(2)}
                        </div>
                        <div className="data-card">
                            期间年化收益率:{" "}
                            {(annualizedReturnRate * 100).toFixed(2)}%
                        </div>
                    </div>
                    <div className="chart-container">
                        {/* 柱状图 */}
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart
                                data={filteredData}
                                margin={{
                                    top: 20,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="ledgerDateString" />
                                <YAxis
                                    yAxisId="left"
                                    orientation="left"
                                    stroke="#8884d8"
                                />
                                <YAxis
                                    yAxisId="right"
                                    orientation="right"
                                    stroke="#82ca9d"
                                />
                                <Tooltip />
                                <Legend />
                                <Bar
                                    yAxisId="left"
                                    dataKey="dailyFundingPayment"
                                    name="利息收入"
                                    fill="#8884d8"
                                />
                                <Bar
                                    yAxisId="right"
                                    dataKey="annualizedDailyReturnRate"
                                    name="每日年化收益率"
                                    fill="#82ca9d"
                                />
                            </BarChart>

                            {/* 折线图 */}
                            <LineChart
                                data={filteredData}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="ledgerDateString"
                                    style={{ fontSize: "12px" }}
                                />
                                <YAxis
                                    domain={[minEndValue, maxEndValue]}
                                    style={{ fontSize: "12px" }}
                                    tickFormatter={(value) => value.toFixed(2)} // 设置标签格式为两位小数
                                />
                                <Tooltip />
                                <Legend />
                                <Line
                                    type="monotone"
                                    dataKey="endValue"
                                    name="账户价值"
                                    stroke="#82ca9d"
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    );
}

export default BitfinexFunding;
