import React, { useState } from "react";
import axios from "axios";
import "./LoginForm.css";

function LoginForm({ onLoginSuccess }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new URLSearchParams();
        formData.append("username", username);
        formData.append("password", password);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/token`,
                formData,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    withCredentials: true,
                }
            );
            if (response.status === 200) {
                onLoginSuccess();
            } else {
                setError("Login failed!");
            }
        } catch (err) {
            setError("Login failed!");
        }
    };
    return (
        <div className="login-container">
            <form onSubmit={handleSubmit} className="login-form">
                <h2>Chainext Dashboard</h2>
                <div className="form-group">
                    <label>Username</label>
                    <input
                        type="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Password</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Sign in</button>
                {error && <p className="error-message">{error}</p>}
            </form>
        </div>
    );
}

export default LoginForm;
