import React, { useState } from "react";
import TopBar from "./navbars/TopBar";
import SideBar from "./navbars/SideBar";
import ContentArea from "./ContentArea";
import "./Dashboard.css"; // 引入Dashboard的CSS样式文件

function Dashboard({ onLogout }) {
    const [content, setContent] = useState("default");

    const handleSelectionChange = (selection_one, selection_two = null) => {
        // 根据传入的选项来更新content状态
        setContent({ selection_one, selection_two });
    };

    return (
        <div className="dashboard">
            <TopBar
                onTopBarSelectionChange={handleSelectionChange}
                onLogout={onLogout}
            />
            <div className="dashboard-main">
                <SideBar onSideBarSelectionChange={handleSelectionChange} />
                <ContentArea content={content} />
            </div>
        </div>
    );
}

export default Dashboard;
